$candle-height: 30vh;

.candleContainer {
  &.lightable:not(.lit) {
    &:hover {
      cursor: pointer;
    }
  }
}

.blend {
  mix-blend-mode: screen;
}

.candle {
  position: absolute;
  max-height: $candle-height;
  transform: translate(-50%); // center horizontally
  bottom: 0;
}

.halo {
  position: absolute;
  max-height: $candle-height;
  transform: translate(-50%); // center horizontally
  bottom: 0;
}

.flame {
  position: absolute;
  bottom: 20vh;
  width: 25vh;
  height: 20vh;
  transform: translate(-50%); // center horizontally
  mix-blend-mode: screen;

  &:full-screen {
    width: 100%;
    height: 100%;
    position: absolute;
  }
}

.animation.animation-tapToLight {
  position: absolute;
  bottom: 20vh + 1vh; // Slight distanciation from wick compared to flame
  transform: translate(-50%); // center horizontally
  mix-blend-mode: screen;
  cursor: pointer;
}
