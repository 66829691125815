@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import './variables.scss';
@import './bootstrap.scss';

@import url('https://use.typekit.net/pba0mgy.css');
@import './candle.scss';

html,
body,
#root {
  height: 100%;
}

.vh-25 {
  height: 25vh;
}
.vh-50 {
  height: 50vh;
}
.vh-75 {
  height: 75vh;
}

.dreidel {
  mix-blend-mode: screen;
  display: block;
  max-width: 10vw;

  &.clickable {
    cursor: pointer;

    &:hover {
      @include transition($transition-fade);
      user-select: none;
      opacity: 0.5;
    }
  }
}

.shareIcon {
  width: 4em;
  height: 4em;
  mix-blend-mode: screen;
}

#experience {
  background-image: url('../media/stars.png'), url('../media/bg_infinite.jpg');
  background-position: center center, center bottom;
  background-repeat: no-repeat;
  background-size: cover, cover;
}

#landing {
  background-image: url('../media/bg_infinite_splash.jpg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
