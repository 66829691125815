$body-bg: #000;

$eggshell: #ffeed7;
$night: #afb5e7;

$theme-colors: (
  'primary': $night,
  'secondary': $eggshell,
);

$link-color: map-get(
  $map: $theme-colors,
  $key: 'primary',
);
// $link-hover-color: shift-color($link-color, $link-shade-percentage);

$body-color: $eggshell;

// FYI PE is a font integrist.
$font-family-serif: dico-mono, mono;

$font-weight-light: 700;
$font-weight-normal: 700;
$font-weight-bold: 700; 
$headings-font-weight: 700;
$font-weight-base: $font-weight-normal !default;

$font-family-base: $font-family-serif;

$container-max-widths: (
  xl: 960px,
);

$modal-backdrop-opacity: 0.9; // .5
$modal-content-bg: $black;

$btn-font-family: dico-mono, mono;
$btn-font-weight: 800; // 800
